<template>
    <div v-loading="loading">
        <top-nav></top-nav>
        <header-guide></header-guide>
        <main
            class="login-main"
            :style="{backgroundImage:'url('+$img.login_bg+')'}"
        >
            <!--登录模块-->
            <transition name="el-zoom-in-center">
                <div
                    class="login-group position"
                    style="height:460px;"
                >
                    <div class="form-group">
                        <div class="guide"></div>
                        <h2 class="nav">绑定您的账号</h2>
                        <el-form
                            ref="form"
                            :rules="formRules"
                            :model="form"
                            :show-message="true"
                            class="form-wrap"
                        >
                            <el-form-item
                                class="form-item"
                                prop="mobile"
                            >
                                <i class="icon">
                                    <img
                                        class="user"
                                        :src="$img.user"
                                    />
                                </i>
                                <el-input
                                    v-model="form.mobile"
                                    class="w334"
                                    placeholder="请输入您注册过的手机号码"
                                    autocomplete="off"
                                ></el-input>
                            </el-form-item>

                            <el-form-item
                                class="form-item"
                                prop="password"
                            >
                                <i class="icon">
                                    <img
                                        class="password"
                                        :src="$img.password"
                                    />
                                </i>
                                <el-input
                                    v-model="form.password"
                                    type="password"
                                    class="w334"
                                    placeholder="请输入您的密码"
                                    autocomplete="off"
                                ></el-input>
                            </el-form-item>
                            <el-button
                                class="submit-btn"
                                @click="bind"
                            >绑定</el-button>
                        </el-form>
                    </div>
                    <div class="login-guide">
                        <p class="name">第一次登录，还没有账号？</p>
                        <p class="guide-tips">
                            不用担心，
                            <router-link :to="{path:'/login/index?type=register',query:{rt:form.reg_type,ocode:form.openid}}">点击这里</router-link>立即注册账号
                        </p>
                    </div>
                </div>
            </transition>
        </main>
        <global-footer></global-footer>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
export default {
    name: "login",
    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        GlobalFooter, //公共底部
    },

    data() {
        let _this = this;
        return {
            loading: false,
            //登录表单
            form: {
                mobile: "",
                password: "",
                openid: 'sdfdsf',
                reg_type: 'q',
            },
            formRules: {
                mobile: [
                    {
                        required: true,
                        message: "手机号码不能为空",
                        trigger: ["change", "blur"]
                    },
                    {
                        validator: _this.validata.mobile(),
                        trigger: ["change", "blur"]
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: ["change", "blur"]
                    }
                ]
            }
        };
    },

    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo"]),//刷新用户数据方法
        //用户绑定
        bind() {
            let _this = this;
            _this.$refs["form"].validate(valid => {
                if (valid) {
                    _this.post("/user/index/bind", _this.form, res => {
                        _this.loading = false;
                        if (res.code == 200) {
                            sessionStorage.setItem("token", res.data); //存储登录信息
                            _this.$message.success("绑定成功");
                            _this.ajaxUserInfo();//刷新用户数据
                            _this.$router.push("/user/index");

                        } else {
                            if (res.msg) {
                                _this.$message.error(res.msg);
                            } else {
                                _this.$message.error('绑定失败');
                            }
                            return false;
                        }

                    }, done => { }, error => {
                        // _this.$message.error('网络错误');
                        // _this.loading = false;
                    });
                }
            });
        },
    },
    created() {
        if (this.$route.query.code) {
            this.form.openid = this.$route.query.code;
            this.form.reg_type = this.$route.query.rt;
        } else {
            this.$router.push('/login/index');
        }
    }
};
</script>
<style lang="less">
@import "../../styles/login.less";
</style>